import { render, staticRenderFns } from "./LabelsList.vue?vue&type=template&id=f322343c&scoped=true&"
import script from "./LabelsList.vue?vue&type=script&lang=js&"
export * from "./LabelsList.vue?vue&type=script&lang=js&"
import style0 from "./LabelsList.vue?vue&type=style&index=0&id=f322343c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f322343c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
