<template>
  <v-menu
    transition="custom-tooltip"
    open-delay="150"
    bottom
    origin="center"
    nudge-left="32px"
    z-index="99"
    offset-overflow
    nudge-bottom="32px"
    min-width="110px"
    content-class="v-tooltip menu-tooltip"
    :open-on-click="false"
    :open-on-focus="false"
    :open-on-hover="true"
    close-delay="0"
    :value="tooltipOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <div @click="handleClick">
        <v-btn
          @mouseleave="handleLeave"
          ref="copyButton"
          :class="`copyButton ml-2`"
          icon
          v-on="on"
          v-bind="attrs"
          x-small
          :color="color"
        >
          <v-icon :color="color" :size="size">$copy</v-icon>
        </v-btn>
      </div>
    </template>

    <div ref="text" class="px-2 py-2 text-center">{{ copyText }}</div>
  </v-menu>
</template>

<script>
export default {
  props: {
    text: String,
    color: {
      default: "info",
      type: String,
    },
    size: {
      default: "16",
      type: String
    }
  },
  data: function () {
    return {
      tooltipOpen: false,
      copyText: "Click to copy",
    };
  },
  methods: {
    handleLeave: function () {
      setTimeout(() => {
        this.copyText = "Click to copy";
      }, 150);
    },
    handleClick: function () {
      this.tooltipOpen = true;

      navigator.clipboard.writeText(this.text);

      this.$nextTick(() => {
        this.copyText = "Copied";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copyButton {
  //   pointer-events: none;
}
</style>