<template>
  <div class="d-flex justify-center">
    <color-swatch
      v-for="(group, index) in predefinedColorGroups"
      :key="group.base + '-' + index"
      :color="group.base"
      :active="compareColorGroups(group)"
      :style="`color: ${group.base}`"
      @click.native="changeColorsTheme(group)"
    />
  </div>
</template>

<script>
import ColorSwatch from "../ColorSwatch.vue";

export default {
  components: {
    ColorSwatch,
  },
  data: function () {
    return {
      predefinedColorGroups: [
        {
          base: "#07C07E",
          lighten1: "#1FD895",
          lighten2: "#CDF2E5",
          lighten4: "#E6F9F2",
          darken1: "#0AAA71",
        },
        {
          base: "#44AA92",
          lighten1: "#345567",
          lighten2: "#CFF0E8",
          lighten4: "#E5F8F3",
          darken1: "#30927A",
        },
        {
          base: "#448ED6",
          lighten1: "#61A6E9",
          lighten2: "#D5E8FB",
          lighten4: "#EAF4FF",
          darken1: "#2B72B8",
        },
        {
          base: "#9D5EAD",
          lighten1: "#9D5EAD",
          lighten2: "#EDD2F4",
          lighten4: "#F6EAF9",
          darken1: "#854495",
        },
        {
          base: "#EF9840",
          lighten1: "#F9AB5D",
          lighten2: "#FDE9D5",
          lighten4: "#FAF1E9",
          darken1: "#E3903C",
        },
      ],
    };
  },
  props: {
    selectedColors: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeColorsTheme: function (theme) {
      this.$emit("update:selectedColors", theme);
    },
    compareColorGroups: function (group) {
      var flag = true;
      Object.keys(this.selectedColors).forEach((item) => {
        this.selectedColors[item];
        if (this.selectedColors[item] != group[item]) {
          flag = false;
        }
      });
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-swatch {
  &.color-swatch--active,
  &:hover {
    &:before {
      border-color: currentColor;
    }
  }
}
</style>