import Vue from 'vue'
import App from './App.vue'
import ConnectionError from './ConnectionError.vue'

import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'
import Api from "./apis/Api";
import Auth from "./apis/Auth";
import { i18next, I18NextVue } from '@/i18n';
import './styles/generic/index.scss'

Vue.use(I18NextVue, { i18next });
Vue.config.productionTip = false

let apiUrl = (() => {
  return process.env.VUE_APP_API_URL || `//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ``}/api`;
})();
Api.defaults.baseURL = apiUrl;
let websocketAuthEndpoint = (() => {
  return process.env.VUE_APP_WEBSOCKET_AUTH_ENDPOINT || `${apiUrl}/broadcasting/auth`;
})();
let websocketHost = (() => {
  return process.env.VUE_APP_WEBSOCKET_HOST || window.location.hostname;
})();
let websocketPort = (() => {
  if (process.env.VUE_APP_WEBSOCKET_PORT) {
    return process.env.VUE_APP_WEBSOCKET_PORT;
  }
  if (window.location.port) {
    return window.location.port;
  }
  if (window.location.protocol == "https:") {
    return 443;
  }
  return 80;
})();
let websocketPath = (() => {
  return process.env.VUE_APP_WEBSOCKET_PATH || "/ws";
})();

store.commit("setEnv", {
  apiUrl: apiUrl,
  websocketAuthEndpoint: websocketAuthEndpoint,
  websocketHost: websocketHost,
  websocketPort: websocketPort,
  websocketPath: websocketPath,
});



Auth.home().then(() => {

  let vue = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  store.$app = vue;

  if (Auth.isLoggedIn()) {
    Auth.changeLanguage(store.state.home.user);
    Auth.websocketConnect();
    Auth.websocketListen(vue);
    Auth.loadNotifications();
  } else {
    Auth.changeLanguage();
  }
}).catch(() => {
  new Vue({
    vuetify,
    render: h => h(ConnectionError)
  }).$mount('#app')
})
