var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"min-width":"375px","max-width":"375px","offset-y":"","nudge-bottom":"8px","close-on-content-click":false,"close-on-click":true,"z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"filter-menu"},[_c('div',_vm._g(_vm._b({staticClass:"custom-menu",class:[
          _vm.menuOpen ? 'custom-menu--active' : '',
          _vm.labelsActive ? 'custom-menu--active-info' : '',
          _vm.$vuetify.breakpoint.smAndDown ? 'custom-menu--sm p-3' : 'p-2' ]},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t('button.filters')))]),(_vm.appliedLabels.length > 0)?_c('div',{staticClass:"filter-number"},[_vm._v(" "+_vm._s(_vm.appliedLabels.length)+" ")]):_vm._e(),_c('div',{staticClass:"v-input__append-inner"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$chevrondown")])],1)]),(_vm.labelsActive)?_c('span',{staticClass:"clear-filter text--info p-4",on:{"click":_vm.removeAllLabels}},[_vm._v(" "+_vm._s(_vm.$t('button.clearFilter'))+" ")]):_vm._e()])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[[_c('div',[_c('div',{staticClass:"px-4 pt-4 heading--text d-flex justify-space-between"},[_c('div',{staticClass:"p-2"},[_vm._v(_vm._s(_vm.$t('form.label.tags')))])]),_c('div',{staticClass:"px-4 pt-2"},[_c('v-autocomplete',{staticClass:"pt-0 mb-4 mt-0",attrs:{"label":_vm.$t('form.placeholder.tags'),"hide-details":"","items":_vm.labels,"append-icon":"","nudge-bottom":"16px","menu-props":{
            contentClass: 'label-autocomplete',
            disabled: true,
          },"item-text":"title","item-value":"title","outlined":"","multiple":"","chips":"","return-object":"","search-input":_vm.searchValue,"deletable-chips":""},on:{"update:searchInput":function($event){_vm.searchValue=$event},"update:search-input":function($event){_vm.searchValue=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('label-chip',{attrs:{"item":item,"xsmall":"","removable":""},on:{"unassign-label":function($event){return _vm.removeLabel(item)}}}),_c('span')]}}]),model:{value:(_vm.selectedLabels),callback:function ($$v) {_vm.selectedLabels=$$v},expression:"selectedLabels"}})],1),_c('transition',{attrs:{"name":"expand-transition"}},[_c('v-list',{staticClass:"d-flex flex-column mb-4 label-list",staticStyle:{"max-height":"304px"}},_vm._l((_vm.hideSelectedLabels),function(item,index){return _c('v-list-item',{key:item.title + index,on:{"click":function($event){return _vm.addLabel(item)}}},[_c('div',{staticClass:"click-target"}),_c('label-chip',{attrs:{"item":item,"xsmall":""}})],1)}),1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex justify-space-between px-4 py-4"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.applyLabels}},[_vm._v(_vm._s(_vm.$t('button.apply')))]),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":_vm.closeMenu}},[_vm._v(_vm._s(_vm.$t('button.cancel')))])],1),_c('v-btn',{staticClass:"clear-filter-btn",attrs:{"elevation":"0","text":"","color":"info"},on:{"click":_vm.removeAllLabels}},[_vm._v(_vm._s(_vm.$t('button.clearFilter')))])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }