<template>
  <v-avatar :min-width="size" :width="size" :height="size" large color="white">
    <v-img
      :width="size"
      :height="size"
      :src="getAvatarUrl(email || $store.state.home.user.email, size)"
    ></v-img>
  </v-avatar>
</template>

<script>
import { getAvatarUrl } from "@/apis/HelperFunctions";

export default {
  data() {
    return {
      getAvatarUrl,
    };
  },
  props: {
    size: {
      type: Number,
      default: 48,
    },
    email: {
      type: String,
      default: ""
    }
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
