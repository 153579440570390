<template>
  <div
    @click="switchActiveColor()"
    class="color-swatch"
    :class="active ? 'color-swatch--active' : ''"
  >
    <div
      class="color-swatch__content"
      :style="{ backgroundColor: color }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    active: Boolean,
  },
  methods: {
    switchActiveColor: function () {
      this.$emit("update:activeColor");
    },
  },
};
</script>

<style lang="scss" scoped>
.color-swatch {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 34px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  &:hover {
    &:before {
      border-color: map-get($gray, darken1);
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid #E5E5F0;
    box-sizing: border-box;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }

  &__content {
    height: 24px;
    width: 24px;
    border-radius: 5px;
  }
  &--active {
    &:before {
      border: 2px solid map-get($gray, darken1);
    }
  }
}
</style>