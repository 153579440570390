const CustomThemeMixin = {
    data: function () {
        return {
            style: {
                disableShadows: this.$store.state.ThemeModule.style.disableShadows,
            }
        }
    },
    computed: {
        themeColors: function () {
            const newTheme = this.$store.state.ThemeModule
            if (Object.keys(newTheme.colors).length) {
                this.replaceStyleVariables(newTheme);
            }

            return newTheme
        },
        themeStyles: function () {
            const newTheme = this.$store.state.ThemeModule
            if (Object.keys(newTheme.colors).length) {
                this.replaceStyleVariables(newTheme);
            }

            return newTheme
        },
    },
    beforeMounted: function () {

    },
    mounted: function () {
        const theme = JSON.parse(localStorage.getItem('theme'))
        if (theme) {
            this.$store.commit('changeColorsTheme', theme)

            this.theme = theme
            if (Object.keys(this.theme.colors).length) {
                this.replaceStyleVariables(this.theme);
            }
        }



        this.$store.subscribe((action) => {
            if (action.type == 'changeColorsTheme') {
                if (Object.keys(action.payload).length) {
                    this.replaceStyleVariables(action.payload);
                }
            }
        })
    },
    methods: {
        replaceStyleVariables(theme) {
            Object.keys(theme.colors).forEach(i => {
                this.$vuetify.theme.themes.light.primary[i] = theme.colors[i];
            });
            this.style = { ...this.style, ...theme.style }
            this.logo = theme.file
        },
    },
}
export default CustomThemeMixin