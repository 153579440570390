<template>
  <v-chip
    class="p-3 font-weight-regular"
    :class="[statusColor(status), { chipTable: small ? 'v-chip--table' : '' }]"
  >
    {{ value }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: String,
    status: {
      type: String,
      default: "Default",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusColor: function (status) {
      if (status == "gray") {
        return `${status} lighten-5 headings--text`;
      } else if(status == "primary") {
        return `${status} lighten-5 white--text`;
      } else {
        return `${status} lighten-5 ${status}--text text--darken-1`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  &.chipTable {
    padding: 2px 8px;
    border-radius: 4px;
    max-height: 24px;
  }
}
</style>