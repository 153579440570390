<template>
  <svg
    width="74"
    height="71"
    viewBox="0 0 74 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.5781 11H21.4219C20.0843 11 19 12.1328 19 13.5301V50.4699C19 51.8672 20.0843 53 21.4219 53H64.5781C65.9157 53 67 51.8672 67 50.4699V13.5301C67 12.1328 65.9157 11 64.5781 11Z"
      fill="#EEEEF4"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M58.5781 17H15.4219C14.0843 17 13 18.1328 13 19.5301V56.4699C13 57.8672 14.0843 59 15.4219 59H58.5781C59.9157 59 61 57.8672 61 56.4699V19.5301C61 18.1328 59.9157 17 58.5781 17Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <mask
      id="mask0_6365_178568"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="20"
      y="46"
      width="32"
      height="4"
    >
      <path
        d="M50.3854 46H21.6146C20.7229 46 20 46.8954 20 48C20 49.1046 20.7229 50 21.6146 50H50.3854C51.2771 50 52 49.1046 52 48C52 46.8954 51.2771 46 50.3854 46Z"
        fill="#EEEEF4"
      />
    </mask>
    <g mask="url(#mask0_6365_178568)">
      <path
        d="M18.848 41.4535L17.2623 43.4314L26.9666 55.5363L28.5522 53.5584L18.848 41.4535Z"
        fill="#EEEEF4"
      />
      <path
        d="M25.1966 41.4503L23.611 43.4283L33.3152 55.5331L34.9009 53.5552L25.1966 41.4503Z"
        fill="#EEEEF4"
      />
      <path
        d="M31.5453 41.4473L29.9597 43.4252L39.6639 55.5301L41.2496 53.5521L31.5453 41.4473Z"
        fill="#EEEEF4"
      />
      <path
        d="M37.8782 41.4639L36.2925 43.4418L45.9968 55.5467L47.5824 53.5688L37.8782 41.4639Z"
        fill="#EEEEF4"
      />
      <path
        d="M44.2044 41.4608L42.6187 43.4387L10.5349 34.4542L-0.67742 49.8388L52.323 55.5436L66.5966 43.4387L44.2044 41.4608Z"
        fill="#EEEEF4"
      />
      <path
        d="M50.5531 41.4577L48.9674 43.4356L58.6717 55.5405L60.2573 53.5626L50.5531 41.4577Z"
        fill="#EEEEF4"
      />
    </g>
    <path
      d="M29.3036 33C29.3036 36.0585 31.0835 38.6908 33.6657 39.9443L29.9805 29.8663C29.5543 30.8189 29.3036 31.8969 29.3036 33Z"
      fill="#797997"
    />
    <path
      d="M42.2145 32.5988C42.2145 31.6462 41.8635 30.9944 41.5878 30.4679C41.1866 29.8412 40.8357 29.2897 40.8357 28.6629C40.8357 27.961 41.3621 27.2841 42.1393 27.2841C42.1644 27.2841 42.2145 27.2841 42.2396 27.2841C40.8607 26.0306 39.0307 25.2534 37.0251 25.2534C34.3176 25.2534 31.961 26.6323 30.5822 28.7381C30.7577 28.7381 30.9332 28.7381 31.0836 28.7381C31.8858 28.7381 33.1393 28.6378 33.1393 28.6378C33.5655 28.6378 33.6156 29.2144 33.1894 29.2646C33.1894 29.2646 32.7632 29.3147 32.312 29.3398L35.1198 37.7131L36.7995 32.649L35.5961 29.3649C35.1699 29.3649 34.7939 29.2897 34.7939 29.2897C34.3677 29.2897 34.4178 28.6378 34.844 28.6629C34.844 28.6629 36.1226 28.7632 36.8747 28.7632C37.6769 28.7632 38.9304 28.6629 38.9304 28.6629C39.3566 28.6629 39.4067 29.2395 38.9805 29.2897C38.9805 29.2897 38.5543 29.3398 38.1031 29.3649L40.8858 37.6629L41.663 35.0807C41.9889 34.0027 42.2396 33.2507 42.2396 32.5738L42.2145 32.5988Z"
      fill="#797997"
    />
    <path
      d="M37.1504 33.6769L34.844 40.3956C35.5459 40.5961 36.273 40.7215 37.0251 40.7215C37.9276 40.7215 38.7799 40.571 39.5822 40.2953C39.5822 40.2702 39.532 40.2201 39.532 40.195L37.1504 33.702V33.6769Z"
      fill="#797997"
    />
    <path
      d="M43.7939 29.2897C43.8189 29.5404 43.844 29.7911 43.844 30.0919C43.844 30.8691 43.6936 31.7465 43.2674 32.8496L40.9109 39.6685C43.2173 38.3398 44.7465 35.8329 44.7465 33C44.7465 31.6462 44.3955 30.3928 43.7939 29.2897Z"
      fill="#797997"
    />
    <path
      d="M37 24C32.0362 24 28 28.0362 28 33C28 37.9638 32.0362 42 37 42C41.9638 42 46 37.9638 46 33C46 28.0362 41.9638 24 37 24ZM37 41.5989C32.2618 41.5989 28.4011 37.7382 28.4011 33C28.4011 28.2618 32.2618 24.4011 37 24.4011C41.7382 24.4011 45.5989 28.2618 45.5989 33C45.5989 37.7382 41.7382 41.5989 37 41.5989Z"
      fill="#797997"
    />
    <path
      d="M65 62C69.9706 62 74 57.9706 74 53C74 48.0294 69.9706 44 65 44C60.0294 44 56 48.0294 56 53C56 57.9706 60.0294 62 65 62Z"
      fill="#E71D1D"
    />
    <mask
      id="mask1_6365_178568"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="20"
      y="46"
      width="32"
      height="4"
    >
      <path
        d="M50.3854 46H21.6146C20.7229 46 20 46.8954 20 48C20 49.1046 20.7229 50 21.6146 50H50.3854C51.2771 50 52 49.1046 52 48C52 46.8954 51.2771 46 50.3854 46Z"
        fill="#EEEEF4"
      />
    </mask>
    <g mask="url(#mask1_6365_178568)">
      <path
        d="M18.848 41.4534L17.2623 43.4314L26.9666 55.5362L28.5522 53.5583L18.848 41.4534Z"
        fill="#B0B0D3"
      />
      <path
        d="M25.1967 41.4504L23.611 43.4283L33.3153 55.5332L34.9009 53.5552L25.1967 41.4504Z"
        fill="#B0B0D3"
      />
      <path
        d="M31.5453 41.4472L29.9597 43.4251L39.6639 55.53L41.2496 53.5521L31.5453 41.4472Z"
        fill="#B0B0D3"
      />
      <path
        d="M37.8782 41.4639L36.2925 43.4419L45.9968 55.5467L47.5824 53.5688L37.8782 41.4639Z"
        fill="#B0B0D3"
      />
      <path
        d="M44.2044 41.4608L42.6187 43.4387L52.323 55.5436L53.9087 53.5657L44.2044 41.4608Z"
        fill="#B0B0D3"
      />
      <path
        d="M50.5531 41.4577L48.9674 43.4356L58.6717 55.5405L60.2573 53.5626L50.5531 41.4577Z"
        fill="#B0B0D3"
      />
    </g>
    <path
      d="M68 50L62 56"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62 50L68 56"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>