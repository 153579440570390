<template>
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 43.9906H58.1897V40.9613C58.1897 39.8572 57.9272 38.7436 57.4385 37.7622L47.6992 18.1522C47.129 16.7461 45.8889 15.8874 44.495 15.8874H23.1972C21.7943 15.8874 20.5452 16.765 20.0111 18.1145L10.4801 38.5643C10.0366 39.508 9.81027 40.565 9.81027 41.6125V43.9906H8V41.6125C8 40.2819 8.29869 38.9418 8.85083 37.7433L18.3638 17.3501C19.1513 15.34 21.0611 14 23.1972 14H44.495C46.6131 14 48.5138 15.3212 49.3375 17.3595L59.0406 36.9034C59.6651 38.1585 59.9909 39.5646 59.9909 40.9707V44L60 43.9906Z"
      fill="#B0B0D3"
    />
    <path
      d="M8 46.9871H45.0322C47.2833 46.9871 51.5548 46.4329 52.994 42.8491C54.4332 39.2654 53.9258 38 53.9258 38L58.5 38.5L59 51.7069L56.2323 53L9.43922 52.4643L8 49.5179V46.9778V46.9871Z"
      fill="#EEEEF4"
    />
    <path
      d="M56.0989 54H11.9102C9.75596 54 8 52.2541 8 50.1122V40.8878C8 38.7459 9.75596 37 11.9102 37H56.0898C58.244 37 60 38.7459 60 40.8878V50.1122C60 52.2541 58.244 54 56.0898 54H56.0989ZM11.9102 38.7999C10.7516 38.7999 9.81027 39.7358 9.81027 40.8878V50.1122C9.81027 51.2642 10.7516 52.2001 11.9102 52.2001H56.0898C57.2484 52.2001 58.1897 51.2642 58.1897 50.1122V40.8878C58.1897 39.7358 57.2484 38.7999 56.0898 38.7999H11.9102Z"
      fill="#797997"
    />
    <path
      d="M52.5 48C53.8807 48 55 46.8807 55 45.5C55 44.1193 53.8807 43 52.5 43C51.1193 43 50 44.1193 50 45.5C50 46.8807 51.1193 48 52.5 48Z"
      fill="#797997"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>