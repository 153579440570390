var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info",class:_vm.$vuetify.breakpoint.width < 475 ? 'flex-column align-start' : ''},[_c('div',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.width < 475 ? 'ml-4' : ''},[_c('v-avatar',{staticStyle:{"align-self":"center"},attrs:{"size":"32px"}},[_c('v-img',{attrs:{"src":_vm.getAvatarUrl(_vm.item.email, 48)}})],1),_c('div',{staticClass:"d-flex flex-column justify-center ml-3"},[_c('p',{staticClass:"\n          p-2\n          mb-0\n          font-weight-bold\n          gray--text\n          text--darken-2\n          d-flex\n          primary-onhover\n        ",class:_vm.$vuetify.breakpoint.smAndDown
            ? 'flex-column align-start'
            : 'align-center'},[(_vm.isYou)?_c('span',[_vm._v(_vm._s(_vm.$t('table.field.userOwner', {name: _vm.item.name})))]):_c('span',[_vm._v(_vm._s(_vm.item.name))]),(_vm.item.invitationPending)?[_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('StatusLabel',{class:_vm.$vuetify.breakpoint.smAndDown ? 'my-2' : 'ml-2',attrs:{"small":"","status":'info',"value":_vm.$t('message.status.pending')}})],1)]}}],null,false,3563439626)},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("$hourglassIcon")]),_vm._v(" "+_vm._s(_vm.$t('tooltip.sharedAccess.invited', {time: _vm.item.invited_at_text}))+" ")],1)])]:_vm._e()],2),(_vm.item.name !== _vm.item.email)?_c('p',{staticClass:"p-3 mb-0 mt-1 gray--text text--darken-1 primary-onhover"},[_vm._v(" "+_vm._s(_vm.item.email)+" ")]):_vm._e()]),_c('div',{staticClass:"justify-self-end",class:_vm.$vuetify.breakpoint.width < 475 ? '' : 'ml-1'},[_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.item.invitationPending)?_c('v-btn',{staticClass:"d-flex justify-center align-center font-weight-600",class:_vm.hasPendingChanges ? 'error--text' : 'info-onhover',staticStyle:{"margin-right":"-16px"},attrs:{"text":"","loading":_vm.resending,"icon":"","small":"","disabled":!_vm.resendAvailable},on:{"click":function($event){return _vm.$emit('resend-button-clicked', _vm.item)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" $restore ")])],1):_vm._e()],1)]}}])},[_c('span',{},[_vm._v(" "+_vm._s(_vm.resendButtonTooltipText)+" ")])])],1)],1),(_vm.mixedRoles)?_c('div',{staticClass:"justify-self-end",class:_vm.$vuetify.breakpoint.width < 475 ? '' : 'ml-auto'},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.modal.sharedAccess.mixedTole.title'))+" ")]):_vm._e(),_c('div',{staticClass:"justify-self-end d-flex align-center justify-space-between",class:_vm.$vuetify.breakpoint.width < 475 ? '' : 'ml-auto',staticStyle:{"width":"223px"}},[_c('select-dropdown',{staticClass:"ml-4 mt-0 pt-0",attrs:{"outlined":false,"value":_vm.item.role,"itemText":'text',"itemValue":'key',"items":_vm.roles,"taggable":true},on:{"update:value":_vm.handleAccessSettingChange}}),_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"error-onhover",attrs:{"small":"","icon":""},on:{"click":_vm.removeAccess}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$xcircle")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.sharedAccess.removeAccess'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }