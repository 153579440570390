import Api from "@/apis/Api"

export default class {
  constructor(id) {
    this.id = id;
    this.sslDomainsFetched = false;
    this.sslDomainsPromise = false;
    this.sslDomains = new Map();
    this.availableCerts = new Map();

    this.installedCertificates = new Map();
    this.detectedCertificates = new Map();
  }

  resetCache() {
    this.sslDomainsFetched = false;
    this.sslDomainsPromise = false;
    this.sslDomains = new Map();
    this.availableCerts = new Map();

    this.installedCertificates = new Map();
    this.detectedCertificates = new Map();
  }

  getCertificate(domain) {
    return new Promise((resolve) => {
      if (!this.sslDomainsFetched) {
        if (!this.sslDomainsPromise) {
          this.sslDomainsPromise = Api.get(`/server-accounts/${this.id}/ssl/domains`).then((response) => {
            for (const cert of response.data.data) {
              this.sslDomains.set(cert.domain, cert);
            }
            this.sslDomainsFetched = true;
          }).catch(() => {
            this.sslDomainsFetched = true;
          });
        }
        this.sslDomainsPromise.then(() => {
          resolve(this.sslDomains.get(domain));
        })
      } else {
        resolve(this.sslDomains.get(domain));
      }
    });
  }

  getAvailableCertificates(domain) {
    if (!this.availableCerts.has(domain)) {
      this.availableCerts.set(domain, Api.get(`/server-accounts/${this.id}/ssl/certificates?domain=${domain}`).then((response) => {
        return response.data.data;
      }));
    }
    return this.availableCerts.get(domain);
  }


  getInstalledCertificate(domain) {
    return new Promise((resolve) => {
      if (this.installedCertificates.has(domain)) {
        resolve(this.installedCertificates.get(domain));
      } else {
        Api.get(`/server-accounts/${this.id}/ssl/certificate/installed?domain=${domain}`)
          .then(response => {
            const cert = response.data.data;
            if (cert) {
              this.installedCertificates.set(domain, cert);
            }
            resolve(cert);
          });
      }
    });
  }

  getDetectedCertificate(domain) {
    return new Promise((resolve) => {
      if (this.detectedCertificates.has(domain)) {
        resolve(this.detectedCertificates.get(domain));
      } else {
        Api.get(`/server-accounts/${this.id}/ssl/certificate/detected?domain=${domain}`)
          .then(response => {
            const cert = response.data.data;
            if (cert) {
              this.detectedCertificates.set(domain, cert);
            }
            resolve(cert);
          });
      }
    });
  }
}