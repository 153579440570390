<template>
  <div class="template-card">
    <v-card elevation="4">
      <v-img width="100%" height="216px" :src="screenshotUrl">
        <template v-slot:placeholder>
          <ImageLoader />
        </template>
      </v-img>
      <div class="template-card__actions">
        <v-btn
          width="184px"
          small
          elevation="0"
          color="primary"
          @click="$emit('selectTheme', item)"
        >
          <span class="font-weight-bold">{{ $t('button.theme.use') }}</span>
        </v-btn>

        <v-btn
          v-if="item.preview_url"
          width="184px"
          small
          elevation="0"
          outlined
          color="primary"
          class="mt-4"
          @click="$emit('previewTheme', item)"
          :href="item.preview_url"
          target="__blank"
        >
          <span class="font-weight-bold">{{ $t('button.preview') }}</span>
        </v-btn>
      </div>
    </v-card>

    <h6 class="text-center px-4 py-4">{{ item.name }}</h6>
  </div>
</template>

<script>
import ImageLoader from "../ImageLoader.vue";
import Api from "@/apis/Api";

export default {
  components: {
    ImageLoader,
  },
  props: {
    item: Object,
    loading: Boolean,
  },
  computed: {
    screenshotUrl() {
      if (this.item.type && this.item.type === 'custom') {
        let apiUrl = Api.defaults.baseURL;
        return `${apiUrl}/instances/onboarding/theme/${this.item.id}/screenshot`;
      }
      return this.item.screenshot_url;
    }
  }
};
</script>

<style lang="scss" scoped>
.template-card {
  position: relative;
  border-radius: 8px;
  .v-image {
    border-radius: 8px;
  }

  .v-card {
    transition: transform 0.3s ease;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      opacity: 0;
      transition: opacity 0.24s ease;
      z-index: 1;
      backdrop-filter: blur(8px);
      border-radius: 8px;
    }
  }
  &__actions {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;

    transform: translateY(16px);
    transition: transform 0.24s ease, opacity 0.24s ease;
  }
  .v-btn--outlined {
    background-color: #fff;
  }
  &:hover {
    .v-card {
      transform: translateY(-8px);
    }
    .template-card__actions {
      opacity: 1;
      transform: translateY(0);
    }
    .v-card {
      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
