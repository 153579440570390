<template>
  <div class="color-editor">
    <p class="mb-0 font-weight-regular mb-2">{{ colorName }}</p>
    <v-menu
      offset-y
      offset-overflow
      nudge-bottom="8px"
      :close-on-content-click="false"
      :close-on-click="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          elevation="0"
          color="gray text--body"
          block
          small
          v-on="on"
          v-bind="attrs"
          class="text-left justify-start font-weight-regular"
        >
          <div
            class="color-indicator"
            :style="`background-color:${color};`"
          ></div>
          {{ currentColor.toUpperCase() }}
        </v-btn>
      </template>
      <div>
        <v-color-picker
          @update:color="changeColor"
          mode="hexa"
          :value="color"
          width="220px"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      color: "",
      open: false,
    };
  },
  mounted: function () {
    this.color = this.currentColor;
  },
  watch: {
    currentColor: function (newValue) {
      this.color = newValue;
    },
  },
  props: {
    colorKey: String,
    currentColor: {
      type: String,
    },
    colorName: String,
  },
  methods: {
    changeColor: function (color) {
      this.$emit("update:currentColor", {
        index: this.colorKey,
        item: color.hex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.color-indicator {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.color-editor {
  &:not(:last-child) {
    .v-btn {
      margin-bottom: 8px;
    }
  }
  .v-btn {
    &::v-deep {
      padding: 0 7px !important;

      .v-btn__content {
        color: map-get($text, base);
      }
    }
  }
}
.v-application--is-rtl{
    
    .color-indicator {
    margin-right: unset;
    margin-left: 8px;
    }
}
</style>