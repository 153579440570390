<template>
  <div class="language-container" id="languageSwitcher">
    <v-menu
      class="v-menu--language"
      open-delay="150"
      close-delay="150"
      min-width="273px"
      min-height="273px"
      offset-overflow
      nudge-top="24px"
      left
      attach="#languageSwitcher"
      :close-on-content-click="false"
      v-model="isOpen"
      items="languages"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="d-flex align-center"
        >
          <span v-if="isLogin" class="mr-2">Language: </span>
          <v-img
            class="d-flex align-center mr-2"
            position="top center"
            width="21px"
            height="15px"
            :src="getFlagImg(selectedLanguage.value)"
            v-if="getFlagImg(selectedLanguage.value)"
          ></v-img>
          <span :class="isLogin ? 'primary--text' : ''">
                        {{ $t(`form.option.languages.${selectedLanguage.value}`) }}
                    </span>
          <v-icon size="12">$chevrondown</v-icon>
        </div>
      </template>
      <div class="language-dropdown">
        <div>
          <v-text-field
            outlined
            placeholder="Search"
            v-model="inputValue"
            prepend-inner-icon=""
            hide-details=""
            class="language-dropdown__search"
          >
            <template v-slot:prepend-inner>
              <v-icon color="gray darken-1" size="20">$search</v-icon>
            </template>
          </v-text-field>
        </div>

        <v-list
          class="styled-scroll-small my-0 pt-2"
          style="max-height: 328px"
        >
          <template v-if="filteredLanguages && filteredLanguages.length">
            <v-list-item v-for="(language, index) in filteredLanguages" :key="index" @click="selectLanguage(language.value)"
                         :class="language.value == languageCode ? 'v-list-item--is-active' : ''"
            >
              <v-img
                class="d-flex align-center mr-3 flex-grow-0"
                position="top center"
                width="21px"
                height="15px"
                :src="getFlagImg(language.value)"
              ></v-img>
              {{ $t(`form.option.languages.${language.value}`) }}
              <v-icon v-if="language.value == languageCode" color="primary" class="ml-auto">$check</v-icon>

            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              No records found
            </v-list-item>
          </template>

        </v-list>

      </div>
    </v-menu>
  </div>

</template>

<script>
import {changeLanguage} from '../i18n';
import Api from "@/apis/Api";
// import Auth from '../apis/Auth';
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      loading: true,
      isLoadingMoreData: false,
      scrolledToEnd: false,
      inputValue: '',
      page: 1,
      isOpen: false,
      languageCode: null,
      // languages: null,
    };
  },
  mounted() {
    if (this.$store.state.home?.user?.language) {
      this.languageCode = this.$store.state.home.user.language;
    } else if (this.$store.state.home?.app?.default_language) {
      this.languageCode = this.$store.state.home.app.default_language;
    } else {
      this.languageCode = 'en'
    }
  },
  computed: {
    selectedLanguage: function () {
      return { value: this.languageCode }
    },
    filteredLanguages: function () {
      if (!this.inputValue) {
        return this.languages;
      } else {
        return this.languages.filter((item) => {
          return item.text.toLowerCase().includes(this.inputValue.toLowerCase());
        });
      }
    },
    languages: function () {
      return  this.$store.state.home.app.languages.map(lang => {
        return { value: lang }
      })
    }
  },
  methods: {
    getFlagImg(code) {
      if (code) {
        if (code.toUpperCase() == "XX") {
          return `/assets/img/languages/${code.toUpperCase()}.svg`;
        }
        return `/assets/img/languages/${code.toUpperCase()}.png`;
      }
    },
    async selectLanguage(language) {
      this.languageCode = language
      this.isOpen = false
      this.inputValue = ''
      await changeLanguage(language)
      if (!this.isLogin) {
        await Api.put('/user/language', {language: this.languageCode})
          .then(() => {
            this.$store.state.home.user.language = this.languageCode;
          })
        this.$emit('languageChanged');
      }
    }
  }
}
</script>

<style lang="scss" >
.language-dropdown {
  &__search {
    border-radius: unset;
    &.v-input {
      .v-input {
        &__control {
          .v-input {
            &__slot {
              fieldset {
                border: unset;
                border-bottom: 1px solid var(--v-gray-lighten2) !important;
                box-shadow: unset !important;
              }
            }
          }

        }
        &__slot {
          padding: 0 20px !important;
        }
      }
    }

    &:hover {
      .v-input {
        .v-input__control {
          fieldset {
            border-color: var(--v-gray-lighten2) !important;
            box-shadow: unset !important;
          }
        }
      }

    }
  }
  .v-list-item {
    min-height: 44px;
    padding: 0 20px;
  }
}
.language-container {
  position: relative;
  .v-menu {
    &__content {
      top: unset !important;
      bottom: 100% !important;
    }
  }
  > [aria-expanded="true"] {
    color: var(--v-primary-base);
    .v-icon {
      color: var(--v-primary-base);
    }
  }
}
</style>
