var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g({ref:"select",staticClass:"filter-select custom-select",class:("\n  " + (_vm.bold ? 'v-select--select-bold' : '') + " \n  " + (_vm.block ? 'custom-select--block' : '') + "\n  v-input--" + _vm.size + " input-dropdown " + _vm.customClasses),attrs:{"value":_vm.valueData,"outlined":_vm.outlined ? true : false,"items":_vm.items,"taggable":_vm.taggable,"item-value":_vm.itemValue,"item-text":_vm.itemText,"placeholder":_vm.placeholder,"label":_vm.placeholder,"offset":"","menu-props":{
    offsetY: true,
    nudgeBottom: _vm.outlined ? '8px' : undefined,
    closeOnContentClick: false,
    offsetOverflow: true,
    contentClass: 'custom-dropdown-select',
  },"required":_vm.required,"hide-details":_vm.showDetails ? false : true,"return-object":_vm.returnObject},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('loader')]},proxy:true},{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner",function(){return [(_vm.prefix)?_c('div',{staticClass:"v-text-field__prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e()]})]},proxy:true},{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold input-dropdown"},[_vm._v(_vm._s(item.text ? _vm.$t(("form.option." + (item.text))) : item))])]}},{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
  var attrs = ref.attrs;
return [(item.tooltip)?_c('v-tooltip',{attrs:{"open-delay":"200","content-class":"tooltip-left-center","right":"","center":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on2 = ref.on;
  var attrs2 = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"w-100",staticStyle:{"align-self":"stretch"}},'div',attrs2,false),on2),[_c('div',_vm._g(_vm._b({staticClass:"input-dropdown-item"},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.text ? _vm.$t(("form.option." + (item.text))) : item))]),_c('v-icon',{staticClass:"ml-2 input-dropdown-item__icon"},[_vm._v("$check")])],1)])]}}],null,true)},[_vm._v(" "+_vm._s(item.tooltip)+" ")]):_c('div',_vm._g(_vm._b({staticClass:"input-dropdown-item"},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.text ? _vm.$t(("form.option." + (item.text))) : item))]),_c('v-icon',{staticClass:"ml-2 input-dropdown-item__icon"},[_vm._v("$check")])],1)]}},{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$chevrondown")])]},proxy:true}],null,true),model:{value:(_vm.valueData),callback:function ($$v) {_vm.valueData=$$v},expression:"valueData"}},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }