<template>
  <v-btn
    :outlined="outlined"
    elevation="0"
    :text="text"
    :icon="icon"
    class="media-card__btn"
    :class="large || xlarge ? 'pa-4' : 'pa-3'"
    :color="color ? color : 'primary'"
    :to="to ? to : undefined"
    :href="href ? href : undefined"
    :target="targetBlank ? '_blank' : undefined"
    :large="large"
    :x-large="xlarge"
    :small="small"
    :block="block"
    :loading="loading"
    :disabled="disabled"
    v-on="$attrs.listeners"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    to: [Object, String],
    href: String,
    targetBlank: Boolean,
    icon: Boolean,
    outlined: Boolean,
    text: Boolean,
    large: Boolean,
    xlarge: Boolean,
    small: Boolean,
    block: Boolean,
    loading: Boolean,
    color: String,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-btn::v-deep {
  border-radius: 5px !important;
  text-transform: none;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  &.v-btn--icon {
    border-radius: 5px !important;
  }
  &:hover,
  &:active,
  &:focus,
  &[aria-expanded="true"] {
    background-color: var(--v-primary-lighten1) !important;
  }
  &.error,
  &.red {
    &:hover,
    &:active,
    &:focus,
    &[aria-expanded="true"] {
      background-color: var(--v-error-lighten1) !important;
    }

    &.v-btn--outlined {
      border: 1px solid var(--v-error-base);
      background-color: transparent !important;
      &::before {
        display: none;
      }
      &:hover,
      &:active,
      &:focus,
      &[aria-expanded="true"] {
        border-color: var(--v-error-base);
        background-color: var(--v-error-lighten5) !important;
      }
    }
  }
  &.blue,
  &.info {
    &:hover,
    &:active,
    &:focus,
    &[aria-expanded="true"] {
      background-color: var(--v-blue-lighten1) !important;
    }

    &.v-btn--outlined {
      border: 1px solid var(--v-blue-base);
      background-color: transparent !important;
      &::before {
        display: none;
      }
      &:hover,
      &:active,
      &:focus,
      &[aria-expanded="true"] {
        border-color: var(--v-blue-base);
        background-color: var(--v-blue-lighten5) !important;
      }
    }
  }

  &.v-btn--outlined {
    border: 1px solid var(--v-gray-lighten3);
    background-color: white !important;
    &::before {
      display: none;
    }
    &:hover,
    &:active,
    &:focus,
    &[aria-expanded="true"] {
      border-color: var(--v-primary-base);
      // background-color: #07c07e1a !important;
    }
    &.error--text {
      border-color: var(--v-error-base);
      &:hover,
      &:active,
      &:focus,
      &[aria-expanded="true"] {
        border-color: var(--v-error-base);
        background-color: var(--v-error-lighten5) !important;
      }
    }
  }
  &.v-btn--text {
    background-color: transparent !important;
    color: var(--v-primary-base);
    &:before {
      display: none;
    }
    &:hover,
    &:active,
    &:focus,
    &[aria-expanded="true"] {
      color: var(--v-primary-lighten1) !important;
      background-color: transparent !important;
      .v-icon {
        svg {
          color: var(--v-primary-lighten1) !important;
        }
      }
    }
  }

  &__hover {
    position: absolute;
    transform: translateY(16px);
    opacity: 0;
    transition: all 0.3 ease;
  }
  &.is-hovered {
    .v-btn {
      &__content {
        flex-direction: column;
      }
      &__hover {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
</style>
