var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assign-existing-label"},[_c('div',{staticClass:"px-3 py-3 heading--text d-flex justify-space-between"},[_c('div',{staticClass:"p-2 font-weight-bold py-1"},[_vm._v(_vm._s(_vm.$t('heading.instance.tag.assignExisting.title')))]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn-close",on:{"click":function($event){return _vm.closeMenu()}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$close")])],1)]),_c('v-divider'),(_vm.instanceLabels.length > 0)?_c('div',{staticClass:"px-4 pt-4 pb-2 labels-list"},[_c('labels-list',_vm._g({attrs:{"maxLabelsDisplayed":99,"labels":_vm.instanceLabels,"removable":true,"chips":true,"loading":_vm.isLoading,"small":"","containerClass":"ml-0"}},_vm.$listeners))],1):_c('div',{staticClass:"px-4 py-4 labels-list"},[(!_vm.isLoading)?_c('span',{staticClass:"gray--text text--darken-1 text-center mb-0 p-4 font-weight-600"},[_vm._v(" "+_vm._s(_vm.$t('message.tag.noAssigned'))+" ")]):_c('span',[_c('Loader',{attrs:{"noMargins":"","size":"16"}})],1)]),_c('v-divider'),_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"pt-0 mt-0 custom-input",attrs:{"label":_vm.$t('form.placeholder.tag.assign'),"hide-details":"","solo":"","items":_vm.availableLabels,"nudge-bottom":"24px","elevation":0,"flat":"","menu-props":{
        contentClass: 'label-autocomplete',
      },"item-text":"title","item-value":"title"},on:{"update:search-input":function($event){_vm.input = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$chevrondown")])]},proxy:true},{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$search")])]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center px-4 py-4"},[_c('i18next',{attrs:{"translation":_vm.$t('message.tag.noTags')},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('a',{staticClass:"info--text",on:{"click":function($event){return _vm.changeView()}}},[_vm._v(_vm._s(_vm.$t('button.tag.add')))])]},proxy:true}])})],1)]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between w-100 instance-label-list",on:{"click":function($event){return _vm.$emit('assign-label', item)}}},[_c('label-chip',{attrs:{"item":item,"xsmall":""}}),(item.in_use)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","max-width":"148px","content-class":"instance-label-list__tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"\n                  instance-label-list__remove\n                  instance-label-list__remove--disabled\n                ",on:{"click":function($event){$event.stopPropagation();}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" ")])]}}],null,true)},[_c('span',{staticClass:"p-4 text-center"},[_vm._v(_vm._s(_vm.$t('tooltip.label.remove')))])]):_c('div',{staticClass:"instance-label-list__remove",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete-label', item)}}},[_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" ")])],1)]}}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('v-btn',{staticClass:"custom-button",on:{"click":function($event){return _vm.changeView()}}},[_vm._v(_vm._s(_vm.$t('button.addNew')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }