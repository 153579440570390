<template>
  <div class="d-flex">
    <v-menu
      max-width="354px"
      width="100vw"
      offset-y
      nudge-bottom="8px"
      :close-on-content-click="false"
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on, attrs }">
        <div>
          <div
            v-if="checkIfHasLabels"
            class="d-flex flex-nowrap label-bar"
            :class="disabled ? 'label-bar--disabled' : ''"
          >
            <labels-list
              v-bind:active="attrs['aria-expanded'] == 'true' ? true : false"
              :containerClass="containerClass"
              :dots="dots"
              :chips="chips"
              :removable="false"
              :maxWidth="maxWidth"
              :maxLabelsDisplayed="maxLabelsDisplayed"
              small
              xsmall
              :labels="instanceLabels"
              :remainingAsButton="remainingAsButton"
              @showRemaining="toggleMenu"
              @toggleFilter="toggleFilter"
              v-bind="attrs"
              :filtersActive="filtersActive"
              :loading="loading"
              :instance="instance"
            />
          </div>
          <span
            v-else
            v-on="on"
            v-bind="attrs"
            class="label-bar-empty info--text p-4"
            :class="disabled ? 'label-bar--disabled' : ''"
          >
            <template v-if="!loading">{{ $t('button.tag.assign') }}</template>
            <span v-else>
              <loader size="16" />
            </span>
          </span>
        </div>
      </template>
      <add-new-label
        @close="closeMenu"
        @changeView="addNewLabelView = !addNewLabelView"
        v-if="addNewLabelView"
        :availableLabels="availableLabels"
        :instanceLabels="instanceLabels"
        @create-label="createLabel"
        :error="addLabelError"
        ref="AddNewLabel"
        :isLoading="loading"
      />
      <assign-existing-label
        @close="closeMenu"
        @changeView="addNewLabelView = !addNewLabelView"
        v-else
        :availableLabels="availableLabels"
        :instanceLabels="instanceLabels"
        :instance="instance"
        @assign-label="assignLabel"
        @unassign-label="unassignLabel"
        @delete-label="deleteLabel"
        :isLoading="loading"
      />
    </v-menu>
  </div>
</template>

<script>
import {
  addNewLabel,
  addLabelToSubinstance,
  deleteLabelFromUserLabels,
  removeLabelFromSubinstance,
} from "@/router/fetchData.js";

import LabelsList from "../labels/LabelsList.vue";
import AddNewLabel from "./AddNewLabel.vue";
import AssignExistingLabel from "./AssignExistingLabel.vue";

import Loader from "../Loader.vue";

export default {
  components: {
    AssignExistingLabel,
    AddNewLabel,
    LabelsList,
    Loader,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filtersActive: Boolean,
    dots: {
      type: Boolean,
    },
    chips: {
      type: Boolean,
    },
    containerClass: {
      type: String,
      default: "",
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    remainingAsButton: {
      type: Boolean,
    },
    maxLabelsDisplayed: {
      type: Number,
      default: 2,
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    labels: Array,
    instance: Object,
  },
  data: function () {
    return {
      addLabelError: "",
      newLabel: "",
      menuOpen: false,
      addNewLabelView: false,
      maxItems: 0,
      instanceLabels: this.labels,
      loading: false,
    };
  },
  computed: {
    visibleLabels: function () {
      return this.instanceLabels.slice(0, 2);
    },
    hiddenLabelsNumber: function () {
      if (this.instanceLabels.length > 2) {
        return this.instanceLabels.length - 2;
      } else {
        return false;
      }
    },
    userLabels: {
      get: function () {
        return this.$store.state.LabelsModule.availableLabels;
      },
      set: function (newData) {
        this.$store.dispatch("setUserLabels", newData);
      },
    },
    availableLabels: function () {
      if (!this.userLabels) {
        return [];
      }
      return this.userLabels.filter((item) => {
        if (
          !this.instanceLabels.map((item) => item.title).includes(item.title)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    checkIfHasLabels: function () {
      if (this.labels.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleFilter: function (item) {
      this.$emit("toggleFilter", item);
    },
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu: function () {
      this.menuOpen = false;
      setTimeout(() => {
        this.addNewLabelView = false;
      }, 200);
    },
    assignLabel: async function (label) {
      try {
        this.addLabelError = "";
        this.loading = true;

        const { error } = await addLabelToSubinstance(label, this.instance.id);
        this.$store.dispatch("updateLabelInUse", { label, newState: true });

        if (error) {
          throw error;
        }

        this.instanceLabels.push(label);
      } catch (error) {
        this.addLabelError = error;
      } finally {
        this.loading = false;
      }
    },
    unassignLabel: async function (label) {
      try {
        this.loading = true;

        const { data, error } = await removeLabelFromSubinstance(
          label,
          this.instance.id
        );

        if (error) {
          throw error;
        }

        this.$store.dispatch("updateLabelInUse", {
          label,
          newState: data.in_use,
        });

        this.instanceLabels = this.instanceLabels.filter((currentLabel) => {
          return !(
            label.title == currentLabel.title &&
            label.color == currentLabel.color
          );
        });
        
      } catch (error) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: error,
        });
      } finally {
        this.loading = false;
      }
    },
    createLabel: async function (label) {
      const { error } = await addNewLabel(label);

      if (!error) {
        const allLabels = [
          ...this.$store.state.LabelsModule.availableLabels,
          label,
        ];
        this.$store.dispatch("setUserLabels", allLabels);
        await this.assignLabel(label);

        this.addNewLabelView = !this.addNewLabelView;
      } else {
        this.addLabelError = error;
      }
    },
    deleteLabel: async function (label) {
      const { error } = await deleteLabelFromUserLabels(label);
      if (!error) {
        this.userLabels = this.userLabels.filter((currentLabel) => {
          return !(
            label.title == currentLabel.title &&
            label.color == currentLabel.color
          );
        });
      } else {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t('notification.instance.label.delete.error'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label-bar--disabled {
  opacity: 0.6;
  pointer-events: none;
}

.label-bar-empty[aria-expanded="true"] {
  color: map-get($primary, darken1);
}
.label-bar[aria-expanded="true"] {
  .v-btn.v-btn--icon.v-size--x-small {
    border-color: transparent;
    background: map-get($primary, lighten2);
    color: map-get($primary, darken1);
  }
}
.v-btn.v-btn--icon.v-size--x-small {
  max-width: 24px;
  max-height: 24px;
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  padding: 0px !important;
  margin-top: 0px !important;
  border: 1px solid map-get($gray, lighten1);
}
</style>
