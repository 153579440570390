var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-iterator-card",class:[
    _vm.checked ? 'data-iterator-card--selected' : '',
    _vm.item.service_status === 'suspended'
      ? 'data-iterator-card--suspended'
      : '' ]},[_c('v-card',{attrs:{"rounded":"","elevation":"4"}},[(_vm.item.hasInstallStatus())?_c('div',{staticClass:"v-card__img v-card__img-installation-pending",class:{
        'v-card__img-installation-pending--error': _vm.item.hasInstallFailed(),
      }},[_c('instance-card-img-installation-pending',{attrs:{"status":_vm.item.hasInstallFailed() ? 'error' : 'in_progress',"imported":_vm.item.imported}})],1):_c('div',[(!_vm.img && !_vm.imgLoading)?_c('div',{staticClass:"v-card__img v-card__img-not-available"},[_c('instance-card-img-not-available',{attrs:{"instance":_vm.item}})],1):_c('v-img',{staticClass:"data-iterator-card__image",class:_vm.imgLoading ? 'data-iterator-card__image--loading' : '',attrs:{"src":_vm.img ? _vm.img : '',"width":"100%","height":"200px","max-height":"200px","position":"center top"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}])})],1),(_vm.item.service_status === 'suspended')?_c('div',{staticClass:"instance-actions instance-actions--suspended",class:_vm.unsuspendUrl() ? 'cursor-pointer' : '',on:{"click":function($event){_vm.unsuspendUrl() ? _vm.unsuspend() : undefined}}},[_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"max-width":"120px","min-width":"120px"}},[_c('v-icon',{staticClass:"mb-3",attrs:{"size":"32","color":"sand darken-1"}},[_vm._v(" $suspended ")]),_c('p',{staticClass:"font-weight-600 p-2 sand--text text--darken-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t('message.suspended'))+" ")])],1)]):_c('router-link',{staticClass:"instance-actions px-12",attrs:{"to":_vm.item.isActive && _vm.item.service_status == 'active'
          ? { path: ("/instances/" + (_vm.item.id)) }
          : ''}},[_c('instance-item-actions',_vm._g({attrs:{"instance":_vm.item,"state":_vm.installStatus,"checkingWpLogin":_vm.checkingWpLogin,"previewNotAvailable":!_vm.img && !_vm.imgLoading},on:{"openWpLogin":_vm.openWPLoginModal,"cancelInstallation":function($event){return _vm.cancelInstall()},"restartInstallation":function($event){return _vm.retryInstall()}}},_vm.$listeners))],1),(!_vm.item.hasInstallStatus())?_c('div',{staticClass:"data-iterator-card__checkbox-container"},[_c('checkbox',{attrs:{"checked":_vm.checkedComputed},on:{"change":_vm.handleCheckbox}})],1):_vm._e(),_c('v-divider'),_c('v-card-title',[_c('div',{staticClass:"d-flex w-100 justify-space-between flex-nowrap align-start data-iterator-card__title-wrapper"},[(_vm.hasBlockingActionInProgress)?_c('v-tooltip',{attrs:{"open-on-click":"","open-on-hover":"","open-delay":"150","bottom":"","transition":"custom-tooltip","nudge-bottom":"12px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"h5 font-weight-bold data-iterator-card__title data-iterator-card__title--link"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])]}}],null,false,3600008127)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.status.instance.notAvailable')))])]):_c(!_vm.item.hasInstallInProgress() ? 'router-link' : 'div',{tag:"component",attrs:{"to":_vm.item.isActive ? ("/instances/" + (_vm.item.id) + "/general") : ''}},[_c('span',{staticClass:"h5 font-weight-bold data-iterator-card__title data-iterator-card__title--link",domProps:{"textContent":_vm._s(_vm.item.title)}})]),(!_vm.item.hasInstallInProgress())?_c('div',[_c('status-indicator',{attrs:{"instance":_vm.item}})],1):_vm._e()],1),(!_vm.item.hasInstallInProgress())?_c('div',{staticClass:"d-flex data-iterator-card__url-box"},[_c('dns-ssl-indicator',{attrs:{"instance":_vm.item}}),_c('a',{staticClass:"instance-domain-link p-4 ml-1",attrs:{"target":"_blank","href":_vm.item.url},domProps:{"textContent":_vm._s(_vm.item.url)}})],1):_c('v-tooltip',{attrs:{"open-on-click":"","open-on-hover":"","open-delay":"150","bottom":"","transition":"custom-tooltip","nudge-bottom":"12px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex data-iterator-card__url-box"},'div',attrs,false),on),[_c('span',{staticClass:"instance-domain-link p-4",domProps:{"textContent":_vm._s(_vm.item.url)}})])]}}])},[_vm._v(" "+_vm._s(_vm.$t('message.status.instance.notAvailable'))+" ")])],1),_c('v-card-text',{ref:"",staticClass:"pt-3"},[_c('div',{ref:"labelContainer",staticClass:"w-100"},[_c('instance-labels',{attrs:{"disabled":_vm.hasBlockingActionInProgress ? true : false,"remainingAsButton":true,"containerClass":'ml-0',"labels":_vm.item.labels,"maxLabelsDisplayed":99,"chips":true,"xsmall":"","maxWidth":_vm.maxLabelWidth,"instance":_vm.item,"filtersActive":true},on:{"toggleFilter":_vm.toggleFilter}})],1)]),_c('v-card-actions',{staticClass:"justify-space-between pt-3"},[[_c('div',{staticClass:"d-flex"},[_c('instance-subinstance-indicator',{key:("instance-" + (_vm.item.id)),attrs:{"instance":_vm.item,"state":_vm.installStatus},on:{"cancelInstallation":function($event){return _vm.cancelInstall()},"restartInstallation":function($event){return _vm.retryInstall()}}}),_vm._l((_vm.item.subInstances),function(subinstanceItem){return _c('instance-subinstance-indicator',{key:("subinstance-" + (subinstanceItem.id)),attrs:{"instance":subinstanceItem,"state":_vm.installStatus},on:{"cancelInstallation":function($event){return _vm.cancelInstall()},"restartInstallation":function($event){return _vm.retryInstall()}}})})],2)],_c('shares-label',{attrs:{"instance":_vm.item}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }