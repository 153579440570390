<template>
  <svg
    width="65"
    height="90"
    viewBox="0 0 65 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8755 68.9605C-6.39797 60.2313 7.99115 32.5197 28.4102 29.015C51.3109 25.0748 67.3762 42.2286 63.4143 62.0163C59.0388 83.8503 30.2388 94.7565 22.0102 87.0068C17.1775 82.4571 25.232 76.8626 19.2891 71.268C13.3462 65.6735 7.20747 73.0313 2.8755 68.9605Z"
      fill="#F9F9FB"
    />
    <path
      d="M58.4482 28H18.2774C17.4817 28 16.7134 28.1873 16.0274 28.5351L8.20732 32.3612C7.24695 32.7358 7 34.2341 7 35.5987V73.5385C7 74.903 8.17988 76 9.60671 76C9.60671 76 14.9299 71.9866 17.5915 69.7659H58.3933C59.8476 69.7659 61 68.6154 61 67.2241V30.5686C61 29.1505 59.8201 28.0268 58.3933 28.0268L58.4482 28Z"
      fill="#EEEEF4"
    />
    <path
      d="M51.9135 76.8196C50.4708 76.8196 49.1097 75.5402 49.1097 74.2063V35.6332C49.1097 34.2993 49.1097 32.3938 50.5797 32.3938L58.5012 28.12C59.9439 28.12 61.0873 29.2089 61.0873 30.5427V67.5915C61.0873 68.5987 60.6517 69.5514 59.8623 70.2047C57.3034 72.3553 51.9135 76.7652 51.9135 76.7652V76.8196Z"
      fill="#CCCCE2"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M50.3817 33H9.61833C8.17227 33 7 34.1723 7 35.6183V76.3817C7 77.8277 8.17227 79 9.61833 79H50.3817C51.8277 79 53 77.8277 53 76.3817V35.6183C53 34.1723 51.8277 33 50.3817 33Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3163 55.26C21.3163 58.5811 23.2491 61.4394 26.0529 62.8004L22.0513 51.8573C21.5886 52.8918 21.3163 54.0623 21.3163 55.26Z"
      fill="#797997"
    />
    <path
      d="M35.3627 54.8245C35.3627 53.7901 34.9816 53.0823 34.6822 52.5107C34.2466 51.8301 33.8655 51.2312 33.8655 50.5507C33.8655 49.7885 34.4372 49.0535 35.2811 49.0535C35.3083 49.0535 35.3627 49.0535 35.39 49.0535C33.8928 47.6924 31.9056 46.8485 29.7278 46.8485C26.7879 46.8485 24.2291 48.3457 22.7319 50.6324C22.9224 50.6324 23.113 50.6324 23.2763 50.6324C24.1474 50.6324 25.5085 50.5235 25.5085 50.5235C25.9713 50.5235 26.0257 51.1496 25.5629 51.204C25.5629 51.204 25.1002 51.2585 24.6102 51.2857L27.659 60.3777L29.4829 54.8789L28.1762 51.3129C27.7134 51.3129 27.3051 51.2312 27.3051 51.2312C26.8423 51.2312 26.8968 50.5235 27.3596 50.5507C27.3596 50.5507 28.7479 50.6596 29.5645 50.6596C30.4356 50.6596 31.7967 50.5507 31.7967 50.5507C32.2595 50.5507 32.3139 51.1768 31.8511 51.2312C31.8511 51.2312 31.3884 51.2857 30.8984 51.3129L33.92 60.3233L34.7639 57.5194C35.1177 56.3489 35.39 55.5323 35.39 54.7973L35.3627 54.8245Z"
      fill="#797997"
    />
    <path
      d="M29.864 55.995L27.3596 63.2904C28.1218 63.5082 28.9112 63.6443 29.7278 63.6443C30.7078 63.6443 31.6334 63.481 32.5045 63.1815C32.5045 63.1543 32.45 63.0999 32.45 63.0727L29.864 56.0222V55.995Z"
      fill="#797997"
    />
    <path
      d="M37.0777 51.2312C37.1049 51.5034 37.1321 51.7757 37.1321 52.1023C37.1321 52.9462 36.9688 53.899 36.506 55.0967L33.9472 62.501C36.4516 61.0583 38.1121 58.3361 38.1121 55.26C38.1121 53.7901 37.731 52.429 37.0777 51.2312Z"
      fill="#797997"
    />
    <path
      d="M29.7006 45.4874C24.3107 45.4874 19.928 49.8701 19.928 55.26C19.928 60.6499 24.3107 65.0326 29.7006 65.0326C35.0905 65.0326 39.4732 60.6499 39.4732 55.26C39.4732 49.8701 35.0905 45.4874 29.7006 45.4874ZM29.7006 64.5971C24.5557 64.5971 20.3636 60.4049 20.3636 55.26C20.3636 50.1151 24.5557 45.923 29.7006 45.923C34.8455 45.923 39.0377 50.1151 39.0377 55.26C39.0377 60.4049 34.8455 64.5971 29.7006 64.5971Z"
      fill="#797997"
    />
    <path
      d="M15 32V29.0202C15 27.9091 16.0485 27 17.3301 27H24.6699C25.9515 27 27 27.9091 27 29.0202V32"
      fill="white"
    />
    <path
      d="M15 32V29.0202C15 27.9091 16.0485 27 17.3301 27H24.6699C25.9515 27 27 27.9091 27 29.0202V32"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.6755 17H37.3245C36.0407 17 35 17.8992 35 19.0084V26.9916C35 28.1008 36.0407 29 37.3245 29H44.6755C45.9593 29 47 28.1008 47 26.9916V19.0084C47 17.8992 45.9593 17 44.6755 17Z"
      fill="#EEEEF4"
      stroke="#797997"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.6755 1H23.3245C22.0407 1 21 1.89918 21 3.00837V10.9916C21 12.1008 22.0407 13 23.3245 13H30.6755C31.9593 13 33 12.1008 33 10.9916V3.00837C33 1.89918 31.9593 1 30.6755 1Z"
      fill="white"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55 84C59.9706 84 64 79.9706 64 75C64 70.0294 59.9706 66 55 66C50.0294 66 46 70.0294 46 75C46 79.9706 50.0294 84 55 84Z"
      fill="#E71D1D"
    />
    <path
      d="M59 79L51 71"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51 79L59 71"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>