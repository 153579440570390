<template>
  <svg
    width="163"
    height="159"
    viewBox="0 0 163 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.7615 20.5371C73.6425 -3.85178 148.069 15.4606 130.766 76.7193C122.352 106.507 99.4431 117.242 70.7701 117.596C42.0971 117.949 14.1414 107.023 20.7763 78.0741C26.3588 53.7175 65.5919 70.2444 69.7615 20.5371Z"
      fill="#F12828"
      fill-opacity="0.2"
    />
    <path
      d="M91.5 109.5H34.5C32.2909 109.5 30.5 107.709 30.5 105.5V32.5C30.5 30.2909 32.2909 28.5 34.5 28.5H116.5C118.709 28.5 120.5 30.2909 120.5 32.5V64C120.5 66.2091 122.295 68 124.504 68V68C126.714 68 128.509 66.2091 128.509 64V24.5C128.509 20.634 125.375 17.5 121.509 17.5H46.5C44.2909 17.5 42.5 19.2909 42.5 21.5V21.7488"
      stroke="#49495F"
      stroke-linecap="round"
    />
    <path
      d="M39.5 43C39.5 42.1716 40.1716 41.5 41 41.5H60C60.8284 41.5 61.5 42.1716 61.5 43V47C61.5 47.8284 60.8284 48.5 60 48.5H41C40.1716 48.5 39.5 47.8284 39.5 47V43Z"
      stroke="#49495F"
    />
    <path
      d="M99.5 44C99.5 43.1716 100.172 42.5 101 42.5H109C109.828 42.5 110.5 43.1716 110.5 44C110.5 44.8284 109.828 45.5 109 45.5H101C100.172 45.5 99.5 44.8284 99.5 44Z"
      stroke="#49495F"
    />
    <path
      d="M83.5 44C83.5 43.1716 84.1716 42.5 85 42.5H93C93.8284 42.5 94.5 43.1716 94.5 44C94.5 44.8284 93.8284 45.5 93 45.5H85C84.1716 45.5 83.5 44.8284 83.5 44Z"
      stroke="#49495F"
    />
    <path
      d="M39.5 57C39.5 56.1716 40.1716 55.5 41 55.5H109C109.828 55.5 110.5 56.1716 110.5 57V75C110.5 75.8284 109.828 76.5 109 76.5H41C40.1716 76.5 39.5 75.8284 39.5 75V57Z"
      stroke="#49495F"
    />
    <path d="M40 87.5H64.5H83" stroke="#49495F" stroke-linecap="round" />
    <path d="M40 93.5H64.5H73" stroke="#49495F" stroke-linecap="round" />
    <path d="M40 99.5H64.5" stroke="#49495F" stroke-linecap="round" />
    <circle cx="120" cy="97.5" r="16" fill="#F12828" />
    <path
      d="M120 105.833C124.602 105.833 128.333 102.102 128.333 97.5C128.333 92.8976 124.602 89.1666 120 89.1666C115.398 89.1666 111.667 92.8976 111.667 97.5C111.667 102.102 115.398 105.833 120 105.833Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M122.5 95L117.5 100"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M117.5 95L122.5 100"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  width: 200px;
  height: auto;
  max-width: 100%;
}
</style>